<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Bloques</h4>
            <div class="small text-muted">Configuración de bloques para sitio web</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body>
        <b-tabs pills card vertical id="config-blocks">
          <b-tab title="SITIO WEB" active>
            <b-row>              
              <b-col md="6" v-for="(element, index) in arr.blocks.website" :key="index" class="mb-1">
                <b-media tag="div">
                  <template #aside>
                    <b-img height="64" :alt="element.name" :src="element.image"></b-img>
                  </template>                  
                  <h5 class="mt-0 mb-1">{{element.name}}</h5>                                                                        
                </b-media>                
              </b-col>              
            </b-row>
          </b-tab>
          <b-tab title="ECOMMERCE">          
            <b-row>              
              <b-col md="6" v-for="(element, index) in arr.blocks.ecommerce" :key="index" class="mb-1">
                <b-media tag="div">
                  <template #aside>
                    <b-img height="64" :alt="element.name" :src="element.image"></b-img>
                  </template>                  
                  <h5 class="mt-0 mb-1">{{element.name}}</h5>                                                                        
                </b-media>                
              </b-col>              
            </b-row>                      
          </b-tab>                   
          <b-tab title="CURSOS">
            <b-row>              
              <b-col md="6" v-for="(element, index) in arr.blocks.courses" :key="index" class="mb-1">
                <b-media tag="div">
                  <template #aside>
                    <b-img height="64" :alt="element.name" :src="element.image"></b-img>
                  </template>                  
                  <h5 class="mt-0 mb-1">{{element.name}}</h5>                                                                        
                </b-media>                
              </b-col>              
            </b-row>            
          </b-tab>          
          <b-tab title="MARKETPLACE">
            <b-row>              
              <b-col md="6" v-for="(element, index) in arr.blocks.marketplace" :key="index" class="mb-1">
                <b-media tag="div">
                  <template #aside>
                    <b-img height="64" :alt="element.name" :src="element.image"></b-img>
                  </template>                  
                  <h5 class="mt-0 mb-1">{{element.name}}</h5>                                                                        
                </b-media>                
              </b-col>              
            </b-row>                              
          </b-tab>    
          <b-tab title="PERSONALIZADOS">
            <b-row>              
              <b-col md="3" v-for="(element, index) in arr.blocks.custom" :key="index" class="mb-1">
                <b-link @click="createBlocks(element)">
                  <b-media tag="div">
                    <template #aside>
                      <b-img width="64" :alt="element.name" :src="element.image"></b-img>
                    </template>                  
                    <h5 class="mt-0 mb-1">{{element.name}}</h5>                                                                        
                  </b-media>                
                </b-link>
              </b-col>     

              <b-col md="3" class="mb-1 add_blocks">
                <b-link @click="createBlocks()">
                  <b-media tag="div">
                    <template #aside>
                      <b-icon scale="1.5" alt="Agregar Bloque" icon="plus-square" class="mt-1"></b-icon>
                    </template>                  
                    <h5 class="mt-0 mb-1">Agregar Bloque</h5>                                                                        
                  </b-media>         
                </b-link>       
              </b-col>                                          
            </b-row>                              
          </b-tab>                                                                                            
        </b-tabs>
      </b-card>
  
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                                            
          </b-col>          
        </b-row>
      </b-card>
    

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              size="xl"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-tabs>
          <b-tab title="General" active>

            <b-row>   
              <b-col md="12">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"                                    
                                placeholder="Ingresar un nombre">
                  </b-form-input>
                </b-form-group>
              </b-col>            

              <b-col md="6">
                <b-form-group label="Columnas">
                  <b-form-select v-model="crud.form.cols" :options="arr.form.column" size="sm"></b-form-select>              
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Tipo">
                  <b-form-select v-model="crud.form.type_tag" :options="arr.form.type_tag" size="sm"></b-form-select>              
                </b-form-group>
              </b-col>

              <b-col sm="12">
                <b-form-group label="Multiple Items" description="Determina si el bloque tiene multiples items">                
                  <b-form-checkbox v-model="crud.form.multiple" size="sm" switch></b-form-checkbox>                 
                </b-form-group>
              </b-col>  

              <b-col sm="12" v-if="crud.form.id && !crud.form.not_edit">
                <b-button variant="link" class="text-danger" @click="remove(crud.form)">Eliminar</b-button>          
              </b-col>
            </b-row>

          </b-tab>   
          <b-tab title="Campos">
            <Form :key="forceUpdate" 
                  :fields="arr.select.fields" 
                  :columns="arr.select.columns" 
                  :fieldsAvailables="'string,date,email,text,integer,decimal,checkbox,select,image,forms,instagram,color'"
                  @fields-config="setFieldConfig($event)" />
          </b-tab> 
          <b-tab title="Código">            
            <b-tabs pills card>
              <b-tab title="HTML" active>            
                <prism-editor v-model="crud.form.html" language="html" class="content-block-source-code"></prism-editor>                        
              </b-tab>
              <b-tab title="CSS">            
                <prism-editor v-model="crud.form.css" language="css" class="content-block-source-code"></prism-editor>            
              </b-tab>
              <b-tab title="Javascript">            
                <prism-editor v-model="crud.form.js" language="javascript" class="content-block-source-code"></prism-editor>            
              </b-tab>                            
            </b-tabs>
          </b-tab>                            
        </b-tabs>      

        <div slot="modal-footer">          
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()" v-if="!crud.form.not_edit">Guardar</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Imagen from '@/components/inc/image/image'
  import PrismEditor from 'vue-prism-editor'
  import "prismjs";
  import "prismjs/themes/prism.css";
  import Form from '@/components/inc/form/config'

  export default {
    components: {
      Imagen,
      PrismEditor,
      Form,
    },        
    data: () => {
      return {      
        access: {
          module_id: Modules.CONTENIDOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudBlocks',
          elements: {}
        }, 
        crud: {
          form: {
            id: 0,
            name: '',
            multiple: false,
            type: 'custom',
            type_tag: 'body',
            cols: 0,
            fields: null,
            html: '',
            css: '',
            js: '', 
            not_edit: false,           
          }          
        }, 
        arr: {
          blocks: {
            website: [],
            ecommerce: [],
            courses: [],
            marketplace: [],
            custom: [],
          },     
          form: {
            column: [
              {value: 1, text: '1 Columna'},
              {value: 2, text: '2 Columnas'},
              {value: 3, text: '3 Columnas'},            
            ],
            type_tag: [
              {value: 'header', text: '<header />'},
              {value: 'body', text: '<body />'},
              {value: 'footer', text: '<footer />'},
            ]
          },
          select: {
            fields: [],
            columns: [],
          }                    
        },
        modal: {
          form: {
            active: false,
            title: ''
          },          
        },
        forceUpdate: 0,
      }
    },
    watch: {       
      'crud.form.cols': function (newQuestion, oldQuestion) {
        this.arr.select.columns = []
        switch (newQuestion) {
          case 1:
              this.arr.select.columns.push({value: 1, text: '1 Columna'})
            break;
        
          case 2:
              this.arr.select.columns.push({value: 1, text: '1 Columna'})
              this.arr.select.columns.push({value: 2, text: '2 Columnas'})
            break;

          case 3:
              this.arr.select.columns.push({value: 1, text: '1 Columna'})
              this.arr.select.columns.push({value: 2, text: '2 Columnas'})
              this.arr.select.columns.push({value: 3, text: '3 Columnas'})
            break;
        }    
        
        this.getForceUpdate()
      },
    },    
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
    },
    methods: {   
      load() {        
        let loader = this.$loading.show();    

        var result = serviceAPI.obtenerBloques()

        result.then((response) => { 
          var data = response.data
          
          this.arr.blocks.website = []
          this.arr.blocks.ecommerce = []
          this.arr.blocks.courses = []
          this.arr.blocks.marketplace = []
          this.arr.blocks.custom = []

          data.forEach(element => {            
            switch(element.type) {
              case 'website':    
                this.arr.blocks.website.push(element)
                break;

              case 'ecommerce':    
                this.arr.blocks.ecommerce.push(element)
                break;

              case 'courses':    
                this.arr.blocks.courses.push(element)
                break;

              case 'marketplace':    
                this.arr.blocks.marketplace.push(element)
                break;   

              case 'custom':    
                this.arr.blocks.custom.push(element)
                break;   
            }            
          });

          loader.hide()
        })
        .catch(error => {                   
          loader.hide()                 
          this.$awn.alert(Error.showError(error))
        });        
      },
      createBlocks(item) {        
        if(!item) {
          this.crud.form.id = 0
          this.crud.form.name = ''
          this.crud.form.multiple = false
          this.crud.form.type = 'custom'
          this.crud.form.cols = 0
          this.crud.form.fields = []
          this.crud.form.html = ''
          this.crud.form.css = ''
          this.crud.form.js = ''
          this.crud.form.type_tag = 'body'
          this.crud.form.not_edit = false,           

          this.modal.form.title = "Nuevo Bloque Personalizado"
        } else {
          this.crud.form.id = item.id
          this.crud.form.name = item.name
          this.crud.form.multiple = item.multiple
          this.crud.form.type = item.type
          this.crud.form.cols = item.cols
          this.crud.form.fields = JSON.parse(item.fields)
          this.crud.form.html = item.source_code
          this.crud.form.css = item.css
          this.crud.form.js = item.js
          this.crud.form.type_tag = item.type_tag
          this.crud.form.not_edit = item.not_edit           

          this.modal.form.title = "Editar Bloque Personalizado"
        }
        
        if(this.crud.form.fields) {
          this.arr.select.fields = this.crud.form.fields
        } else {
          this.arr.select.fields = []
        }       
        this.modal.form.active = true        
      },
      setFieldConfig(fields) {
        this.crud.form.fields = fields
      },
      save() {
        let loader = this.$loading.show();

        var valid = this.validFormConfig()
        if(valid) {
          loader.hide()
          this.$awn.alert(valid);
          return false
        }

        var result = null
        if(this.crud.form.id > 0) {
          result = serviceAPI.editarBloques(this.crud.form)
        } else {
          result = serviceAPI.agregarBloques(this.crud.form);
        }
        
        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      remove(item) {
        this.$bvModal.msgBoxConfirm('¿Desea borrar el bloque (' + item.id + ') - '+ item.name + '?', {
          title: 'Borrar Bloque',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarBloques(item.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })        
      },
      getForceUpdate() {
        this.forceUpdate++
      },
      validFormConfig() {
        var status = true
        if(this.crud.form.fields) {
          this.crud.form.fields.forEach(element => {
            if(element.name=='html') {
              status = false
            }
            if(element.name=='javascript') {
              status = false
            }
            if(element.name=='css') {
              status = false
            }                    
          });
        }

        if(!status) {
          return "Los campos html, javascript y css son reservados del sistema"
        }            

        return ''
      },      
    }    
  }
</script>
<style>
  #config-blocks .media-body {
    align-self: center;
  }
  .add_blocks {
    padding-top: 10px;
    padding-left: 40px;
  }
  .content-block-source-code {
    height: 400px;
  }
  .content-block-source-code pre{
    height: 400px;
  }   
</style>